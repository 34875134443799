import { AppDataService } from './services/app-data.service';
import { CommonService } from './services/common.service';
import { Component, HostBinding, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy{
  @HostBinding('class') componentCssClass: any;

  subscription: Subscription;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    public overlayContainer: OverlayContainer,
    private commonService: CommonService,
    private appDataService: AppDataService,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
      this.appDataService.mobileQuerySend(this.mobileQuery.matches);
    }
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  ngOnInit() {
    let tema = this.commonService.getTema() || 'light-theme';
    this.onSetTheme(tema);

    this.subscription = this.appDataService.textSended$.subscribe(
      tema => {
        this.onSetTheme(tema);
      }
    );
    this.appDataService.mobileQuerySend(this.mobileQuery.matches);
  }

  public onSetTheme(e: string) {
    this.componentCssClass = e;
    const aux = (e === 'light-theme');
    let temaContrario = (aux ? 'dark-theme' : 'light-theme');
    if(e === 'light-theme')
    {
      this.overlayContainer.getContainerElement().classList.remove('dark-theme');
      this.overlayContainer.getContainerElement().classList.add('light-theme');
    }else{
      this.overlayContainer.getContainerElement().classList.add('dark-theme');
      this.overlayContainer.getContainerElement().classList.remove('light-theme');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
}
