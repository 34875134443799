import { Injectable } from '@angular/core';
import { RolC } from 'src/app/model/rol';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

const TOKEN = 'TOKEN';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private authenticationService: AuthenticationService) {}

  setRoles = (rol: RolC): void =>
    sessionStorage.setItem('ROLES', JSON.stringify(rol));
  getRoles = (): RolC =>
    JSON.parse(sessionStorage.getItem('ROL') || '') as RolC;

  setRolActivo = (rol: RolC): void =>
    sessionStorage.setItem('ROL', JSON.stringify(rol));
  getRolActivo = (): RolC =>
    JSON.parse(sessionStorage.getItem('ROL') || '') as RolC;

  setGestion = (gestion: string): void =>
    sessionStorage.setItem('GESTION', gestion);
  getGestion = (): string => sessionStorage.getItem('GESTION') || '';

  getTema = (): any => sessionStorage.getItem('TEMA');
  setTema = (tema: string): void => sessionStorage.setItem('TEMA', tema);

  getReportType = (): string => sessionStorage.getItem('REPORT-TYPE')?.toString() || 'A';
  setReportType = (tema: string): void => sessionStorage.setItem('REPORT-TYPE', tema);

  getIdUsuario = (): number =>
    +this.authenticationService.getPayload().idUsuario;

  isLogged = (): boolean => sessionStorage.getItem(TOKEN) != null;

  getLogo(): string {
    let fecha: Date = new Date();
    if (fecha.getMonth() >= 11) {
      return 'assets/SIFU-nav.png';
    }
    return 'assets/SIFU.png';
  }

  mostrarMensaje(mensaje: string, title: string, tipo?: SweetAlertIcon){
    tipo = (typeof tipo !== 'undefined') ? tipo : 'success';

    Swal.fire({
      toast: true,
      position: 'top-end',
      timer: 5000,
      timerProgressBar: true,
      showCloseButton: true,
      icon: tipo,
      title: title,
      html:
         mensaje||
        'No se tiene una descripción del resultado de la operación',
    });
  }
}
