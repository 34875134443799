import { PortalComponent } from './layout/portal/portal.component';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { LoginGuard } from './guards/login.guard';
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LoaderInterceptor } from './interceptors/loader-interceptor';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule.forRoot([
      // {
      //   path: 'home',
      //   component: PortalComponent,
      //   canActivate: [LoginGuard]
      // },
      {
        path: '',
        loadChildren: () =>
          import('./layout/layout.module').then((mod) => mod.LayoutModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ]),
  ],
  providers: [
    MediaMatcher,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
