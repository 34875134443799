import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private commonService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('JWT interceptor')
    if (this.commonService.isLogged()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.commonService.getToken()}`
        }
      });
    }
    return handler.handle(request);
  }
}


