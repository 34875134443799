import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AppDataService {

  private textSource = new Subject<string>();
  private mobileQuerySource = new Subject<boolean>();
  private mobileQueryResult: boolean;

  textSended$ = this.textSource.asObservable();
  mobileQuerySended$ = this.mobileQuerySource.asObservable();

  textSend(text: string){
    this.textSource.next(text);
  }

  mobileQuerySend(mobileQuery: boolean){
    this.mobileQueryResult = mobileQuery;
    this.mobileQuerySource.next(mobileQuery);
  }

  mobileQueryManual(): any{
    return this.mobileQueryResult;
  }

}
