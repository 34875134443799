import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { catchError, map, Observable, timeout } from 'rxjs';
import { UsuarioC } from '../model/usuario';
import { UsuarioRolC } from '../model/usuario-rol';
import { CLIENTID, URI } from '../utils/constants/uri/uri.constants';

const TOKEN = 'TOKEN';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private clientIP: string = 'anonymous';
  constructor(private http: HttpClient) {}

  authenticationService(usuario: UsuarioC) {
    const params = new URLSearchParams();

    this.getIPAddress().subscribe((ip) => {
      this.clientIP = ip;
    });

    console.log(window);
    params.set('grant_type', 'password');
    params.set('username', usuario.usuario);
    params.set('password', usuario.password);
    params.set('clientIP', this.clientIP);

    return this.http.post<any>(URI + '/oauth/token', params.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization:
          'Basic ' +
          Buffer.from(CLIENTID + ':' + 'leoCapr1Dt1c.23').toString('base64'),
      }),
    });
  }
  getIPAddress(): Observable<string> {
    return this.http.get<any>('https://api.ipify.org/?format=json').pipe(
      timeout(5000), // Establece un tiempo límite de 5 segundos (ajústalo según tus necesidades)
      map((res: any) => res.ip),
      catchError((error) => {
        alert('Revise su conexión a internet.');
        console.error('Error al obtener la dirección IP:', error);
        return window.location.hostname;
      })
    );
  }

  usuarioRolLoginS(
    usuario: UsuarioC,
    header: HttpHeaders
  ): Observable<UsuarioRolC[]> {
    return this.http.get<UsuarioRolC[]>(
      URI + `/usuarioRol?idUsuario= ${usuario.idUsuario}`,
      { headers: header }
    );
  }

  invalidadToken(token: string): Observable<any> {
    return this.http.delete(`${URI}/auth/invalidar`, {
      headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
    });
  }

  getLogin = (): any => {
    const DATA_TOKEN: any = this.getPayload();
    if (DATA_TOKEN === null) {
      return null;
    } else {
      return {
        idUsuario: DATA_TOKEN.user_name,
      };
    }
  };

  setToken = (token: string): void => sessionStorage.setItem(TOKEN, token);

  getToken = (): any => sessionStorage.getItem(TOKEN);

  isLogged = (): boolean => sessionStorage.getItem(TOKEN) != null;

  logout = (): void => {
    let token = sessionStorage.getItem(TOKEN);
    if (token) {
      this.invalidadToken(token).subscribe({
        next: (data) => {
          if (data.estado) {
            console.log("Se ha invalidado el token correctamente");
          }
        },
        error: (err) => {},
      });
    }
    sessionStorage.clear();
  };

  getPayload = (tokken?: string) => {
    let token = sessionStorage.getItem(TOKEN) || tokken;
    if (token != null) {
      let payload = token.split('.')[1];
      return JSON.parse(Buffer.from(payload, 'base64').toString('ascii'));
    }
    return null;
  };

  envS() {
    return this.http.get<boolean>(URI + '/info/env');
  }

  //   (value = "/eliminarTokens")
  // 	public ResponseEntity<Map<String, Object>> eliminarTokensUsuario(@RequestHeader(required = true) String userName,
  // 			@RequestHeader(required = true) String clientId) {
  // }

  eliminarTokensUsuario(userName: string): Observable<any> {
    return this.http.delete(
      `${URI}/auth/eliminarTokens?clientId=${CLIENTID}&userName=${userName}`
    );
  }

  verificarToken(token: string): Observable<any> {
    return this.http.get<any>(`${URI}/auth/check_token?token=${token}`);
  }
}
