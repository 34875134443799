import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import {
  LoaderOverlayService,
  LoadingOverlayRef,
} from './../services/loader-overlay.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private loadingRef: LoadingOverlayRef;

  private httpExceptions: string[] = [
    'comunicado',
    '/file',
    'Array',
    'listadoSolicitudApertura',
  ];
  private overlayExceptions: string[] = ['listadoSolicitudApertura', 'listadoTesoro'];
  constructor(
    private commonService: CommonService,
    private loaderOverlayService: LoaderOverlayService, // private ngxService: NgxUiLoaderService
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  private showLoader(): void {
    if (this.activeRequests === 0) {
      // Si no hay solicitudes activas, abrir el overlay
      this.loadingRef = this.loaderOverlayService.open();
    }
    this.activeRequests++;
  }

  private hideLoader(): void {
    this.activeRequests--;
    if (this.activeRequests === 0 && this.loadingRef) {
      // Cerrar el overlay cuando no hay solicitudes activas
      this.loadingRef.close();
    }
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isOverlayException = this.overlayExceptions.some((exception) =>
      req.url.includes(exception)
    );

    if (!isOverlayException) {
      this.showLoader();
    }

    const isException = this.httpExceptions.some((exception) =>
      req.url.includes(exception)
    );

    if (this.commonService.isLogged()) {
      switch (req.method) {
        case 'POST':
        case 'PUT':
          if (req.body && typeof req.body === 'object' && !isException) {
            let modifiedBody = { ...req.body };

            if (!modifiedBody.gestion) {
              modifiedBody = {
                ...modifiedBody,
                gestion: this.commonService.getGestion(),
              };
            }

            if (!modifiedBody.idUsuario) {
              modifiedBody = {
                ...modifiedBody,
                idUsuario: this.commonService.getIdUsuario(),
              };
            }

            req = req.clone({ body: modifiedBody });
          }
          break;
        default:
          break;
      }
    }
    return next.handle(req).pipe(
      tap(),
      catchError((error: HttpErrorResponse) => {
        const requestedUrl = this.router.url;
        console.log('PATH {}', requestedUrl);
        if (error.status === 401 && requestedUrl !== '/login') {
          // Manejar el error 401
          console.log('Acceso no autorizado - 401');
          // Redirigir al usuario a la página de inicio de sesión

          Swal.fire({
            title: 'Su sesión ha caducado.<br>¿Desea volver a iniciar sesión?',
            showDenyButton: true,
            confirmButtonText: 'SI',
            denyButtonText: `NO`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.authenticationService.logout();
              this.router.navigateByUrl(this.router.createUrlTree(['/login']));
            }
          });
        }
        // return throwError(() => error);
        return throwError(() => error);
      }),
      // Lógica para interceptar la respuesta si es necesario

      finalize(() => {
        if (!isOverlayException) {
          this.hideLoader();
        }
      })
    );
  }
}
